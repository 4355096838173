import React, { lazy, Suspense, useEffect } from "react";

import { CalloutBanner } from "../components/CalloutBanner";
import { HomeCallout } from "../components/HomeCallout";
import { HomeProducts } from "../components/HomeProducts";

import { useMetaStore } from "../stores/MetaStore";

const LiveChat = lazy(() => import("../components/LiveChat"));

export const Home = () => {
	useEffect(() => {
		useMetaStore.getState().SetMeta({
			title: "123 Flowers | Flower Delivery as Easy as 123 | Guaranteed Fresh Flowers",
			description: "123 Flowers offers a wide range of fresh flowers, bouquets, and gifts for all occasions. Order before 3 PM for same-day delivery.",
			keywords: "order flowers online, flower delivery, fresh flowers",
			h1: "123 Flowers: Flower Delivery as Easy as 123",
			h2: "Stunning Flower Delivery for Every Occasion and Sentiment",
			canonical: "https://www.123-flowers.co.uk",
		});
	}, []);

	return (
		<div className="home">
			<HomeCallout />
			<CalloutBanner />
			<HomeProducts />

			<Suspense fallback={null}>
				<LiveChat />
			</Suspense>
		</div>
	);
};

export default Home;
