import { create } from "zustand";
// import { log } from "./helpers";
import { ProductApi } from "../api/ProductApi";
import { RecordAMessageApi } from "../api/RecordAMessage";

import { devtools, persist } from "zustand/middleware";

import Client from "getaddress-api";

import { useCartStore } from "./CartStore";
import { useCategoriesStore } from "./CategoriesStore";

import Config from "../Config";

import { useAppStore } from "./AppStore";

//Address Autocomplete
const address_api = new Client("i8NauK2GE0Ot5_Dn2P5PcQ3331");
const AddressAutocomplete = async (address) => {
	if (address.length >= 4) {
		const addressLookup = await address_api.find(address);
		if (addressLookup.isSuccess) {
			const success = addressLookup.toSuccess();
			if (success.addresses.addresses.length > 0) {
				return success.addresses;
			}
		}
	}

	return [];
};

const itemSpecific = {
	info: {},
	messageCardText: "",
	messageCardType: "standard",
	addressSuggestions: {},
	showAddressSuggestions: false,
	relatedProducts: [],
	cartId: false,
	address: {
		line1: "",
		line2: "",
		town: "",
		county: "",
		postcode: "",
		country: ""
	},
	sizes: [
		{
			size_text: "Standard",
			size: "standard",
			price: 0,
			stem_count: 6
		},
		{
			size_text: "Medium",
			size: "medium",
			price: 5,
			stem_count: 10
		},
		{
			size_text: "Large",
			size: "large",
			price: 10,
			stem_count: 14
		}
	],
	selectedDeliveryOption: null,
	selectedSize: "standard",
	selectedAddons: [],
	selectedDeliveryDate: new Date(),
	currentProductStep: 0,
	printzwareId: null,
	videoMsg: null
};

const initialState = {
	id: null,
	productsInfo: [],
	deliveryOptions: {},
	productSteps: ["Product Details", "Configure Your Gift"],
	availableAddons: {},
	price: 0,
	originalPrice: 0,
	msrpPrice: 0,
	includedAddons: {},
	addonSort: [],
};

const useProductStore = create(
	persist(
		devtools((set, get) => ({
			...itemSpecific,
			...initialState,
			async GetInfo(id, forceUpdate = false, resetStore = false) {
				if (resetStore === true) {
					get().ResetProductStore();
				}

				const info = await get().GetProductInfo(id, forceUpdate);
				set({ info, id });
				get().GetSizes();
				get().SetPrice();
				get().GetRelatedProducts(id);
				get().SetAvailableAddons();

				if (info.delivery.upcoming_dates && info.delivery.upcoming_dates.length > 0) {
					//should always be set but just in case
					get().GetDeliveryMethods(id, new Date(info.delivery.upcoming_dates[0]));
				}
			},
			async GetProductInfo(id, forceUpdate = false) {
				if (!id) throw new Error("Invalid ID");

				const cachedProduct = get().productsInfo.find((product) => product.id === id);

				let cacheNeedsUpdate = false;
				if (cachedProduct && cachedProduct.cached_date) {
					const currentDate = new Date();
					const cachedDate = new Date(cachedProduct.cached_date);

					const difference = currentDate.getTime() - cachedDate.getTime();
					const minutesDifference = Math.floor(difference / 1000 / 60);

					if (minutesDifference >= Config.cacheTimes.product) {
						cacheNeedsUpdate = true;
					}
				} else {
					if (useAppStore.getState().isDebug) {
						console.log(`ProductStore.js - No cache date for product ${id}, forcing update`);
					}

					forceUpdate = true;
				}

				if (cachedProduct) {
					if (forceUpdate || cacheNeedsUpdate) {
						if (useAppStore.getState().isDebug) {
							console.log(`ProductStore.js - Cache update for product ${id}`);
						}
						const updatedProduct = await get().updateProductInfo(id);
						return updatedProduct;
					}

					return cachedProduct;
				}

				return get().updateProductInfo(id);
			},
			async GetProductInfoBulk(ids = [], forceUpdate = false) {
				if (!ids.length) throw new Error("Invalid IDs");

				const cachedProducts = ids.reduce(
					(acc, id) => {
						const cachedProduct = get().productsInfo.find((product) => product.id === id);
						let cacheNeedsUpdate = false;
						if (cachedProduct && cachedProduct.cached_date) {
							const currentDate = new Date();
							const cachedDate = new Date(cachedProduct.cached_date);

							const difference = currentDate.getTime() - cachedDate.getTime();
							const minutesDifference = Math.floor(difference / 1000 / 60);

							if (minutesDifference >= Config.cacheTimes.product) {
								cacheNeedsUpdate = true;
							}
						} else {
							if (useAppStore.getState().isDebug) {
								console.log(`ProductStore.js - No cache date for products ${ids.join(", ")}, forcing update`);
							}
							forceUpdate = true;
						}

						if (cachedProduct) {
							if (forceUpdate || cacheNeedsUpdate) {
								acc["fetch"].push(id);
							} else {
								acc["cached"].push(id);
							}
						} else {
							acc["fetch"].push(id);
						}

						return acc;
					},
					{ cached: [], fetch: [] }
				);

				if (useAppStore.getState().isDebug) {
					console.log(`ProductStore.js - Fetching cached products`, cachedProducts);
				}

				if (cachedProducts["fetch"].length) {
					await get().updateProductInfoBulk(cachedProducts["fetch"]);
				}

				return get().productsInfo;
			},
			async updateProductInfo(id) {
				try {
					const response = await ProductApi.getInfo(id);
					const product = response.product;
					product.cached_date = new Date();
					set((prev) => {
						const updatedProducts = prev.productsInfo.filter((product) => product.id !== id);
						return { productsInfo: [...updatedProducts, product] };
					});
					setTimeout(() => {
						get().GetTags(product);
					}, 0);
					return product;
				} catch (error) {
					console.error("Error fetching product info:", error);
					throw error;
				}
			},
			async updateProductInfoBulk(ids = []) {
				try {
					const response = await ProductApi.getInfoBulk(ids);

					if (useAppStore.getState().isDebug) {
						console.log(`ProductStore.js - Updating product info for ${ids.join(", ")}`, response);
					}

					const products = response.products;
					const productsCache = Object.values(products).map((product) => {
						product.cached_date = new Date();
						return product;
					});
					Object.values(productsCache).forEach((product) => {
						get().GetTags(product);
					});
					set((prev) => {
						const updatedProducts = prev.productsInfo.filter((product) => !ids.includes(product.id));
						return { productsInfo: [...updatedProducts, ...productsCache] };
					});
					return productsCache;
				} catch (error) {
					console.error("Error fetching product info:", error);
					throw error;
				}
			},
			GetSizes() {
				let info = get().info;

				if (info.has_sizes && info.sizes.length > 0) {
					set({ sizes: info.sizes });
					get().SetSize(info.sizes[0].size);
				} else if (info.has_sizes) {
					set({ sizes: itemSpecific.sizes });
					get().SetSize(itemSpecific.sizes[0].size);
				} else {
					set({ sizes: {} });
				}
			},
			GetTags(info) {
				let tags = [];
				if (info.product_meta && info.product_meta.keywords && info.product_meta.keywords !== "") {
					tags = info.product_meta.keywords.split(",");
				}

				useCategoriesStore.getState().AddAvailableTags(tags);
			},
			async GetRelatedProducts(id) {
				let { suggestions } = await ProductApi.getRelated(id);
				set({ relatedProducts: Object.values(suggestions) });
			},
			async GetDeliveryMethods(id, date) {
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
				const day = String(date.getDate()).padStart(2, "0");
				const formattedDateString = `${year}-${month}-${day}`;
				const deliveryOptions = await ProductApi.getDeliveryMethods(id, formattedDateString);
				await get().SetDeliveryMethods(deliveryOptions);
				get().SetSelectedDeliveryMethod(0); //default to the first option
			},
			async SetDeliveryMethods(deliveryOptions) {
				await set({ deliveryOptions: deliveryOptions });
			},
			SetSelectedDeliveryMethod: (option) => {
				let deliveryOptions = get().deliveryOptions;
				if (deliveryOptions[option]) {
					set({ selectedDeliveryOption: deliveryOptions[option] });
				}
				get().SetPrice();
				get().UpdateProductDetails();
			},
			async UpdateProductDetails() {
				if (useAppStore.getState().isDebug) {
					console.log("ProductStore.js - Updating product details", get().cartId);
				}

				if (get().cartId !== false && useCartStore.getState().cart[get().cartId]) {
					try {
						if (useAppStore.getState().isDebug) {
							console.log(`ProductStore.js - Updating product in cart with updated info`);
						}
						useCartStore.getState().UpdateProductInCart(get().cartId, get().BuildProductInfo());
					} catch {
						console.error(`Tried to update product with id ${get().cartId} but no cart id was set`);
					}
				}
			},
			SetMessageCardText: (text) => {
				set({ messageCardText: text });
				get().UpdateProductDetails();
			},
			SetMessageCardType: (type) => {
				set({ messageCardType: type });
				get().UpdateProductDetails();
			},
			SetPrintzwareId: (id) => {
				set({ printzwareId: id });
			},
			UploadVideoMsg: async (recordingType, base64) => {
				try {
					const { recording } = await RecordAMessageApi.uploadRecording(recordingType, base64);
					const { errors, location } = recording;
					if (errors.length) {
						throw new Error(errors);
					} else {
						set({ videoMsg: location });
					}
				} catch (error) {
					console.error(`Failed to upload recording: ${error}`);
				}
			},
			SetVideoMsg: (videoMsg) => {
				set({ videoMsg });
			},
			SetSelectedDeliveryDate: (option) => {
				const date = new Date(option);
				set({ selectedDeliveryDate: date });
				get().GetDeliveryMethods(get().id, date);
				get().UpdateProductDetails();
			},
			SetCurrentProductStep(stepDirection) {
				let currentStep = get().currentProductStep;
				if (stepDirection === "next") {
					currentStep++;
				} else if (stepDirection === "prev") {
					currentStep--;
				} else {
					currentStep = stepDirection;
				}
				set({ currentProductStep: currentStep });
			},
			SetAddons: (addonId) => {
				let addons = [];
				if (get().selectedAddons.includes(addonId)) {
					addons = get().selectedAddons.filter((item) => item !== addonId); //remove
				} else {
					addons = [...get().selectedAddons, addonId]; //add
				}
				set({ selectedAddons: addons });
				get().SetPrice();
				get().UpdateProductDetails();
			},
			SetAvailableAddons: () => {
				let info = get().info;

				let availableAddons = {};
				let includedAddons = {};
				Object.keys(info.addons).forEach((addonType) => {
					if (addonType === "included") {
						info.addons[addonType].forEach((addon, key) => {
							info.addons[addonType][key]["price"] = "0";
							includedAddons[addon.id] = addon;
							if (!get().selectedAddons.includes(addon.id)) {
								const addons = [...get().selectedAddons, addon.id]; //add
								set({ selectedAddons: addons });
							}
						});
					}
					info.addons[addonType].forEach((addon) => {
						availableAddons[addon.id] = addon;
					});
				});

				const addonSort = info.addons.other.map((addon) => addon.id);
				set({ addonSort });

				set({ availableAddons });
				set({ includedAddons });
				return availableAddons;
			},
			SetPrice: () => {
				let info = get().info;
				let originalPrice = get().originalPrice;
				let msrpPrice = get().msrpPrice;

				let sizes = get().sizes;
				let selectedSize = get().selectedSize;

				let selectedAddons = get().selectedAddons;
				let availableAddons = get().availableAddons;

				let deliveryOption = get().selectedDeliveryOption;
				let messageCardType = get().messageCardType;

				let personalisedCard = get().printzwareId;

				let price = parseFloat(info.price_retail);

				if (sizes) {
					price += parseFloat(sizes.find((size) => size.size === selectedSize).price);
					originalPrice = parseFloat(info.price_retail) + parseFloat(sizes.find((size) => size.size === selectedSize).price);
					msrpPrice = parseFloat(info.price_msrp) + parseFloat(sizes.find((size) => size.size === selectedSize).price);
				}

				if (selectedAddons.length > 0) {
					selectedAddons.forEach((addonId) => {
						price += parseFloat(availableAddons[addonId].price);
					});
				}

				if (deliveryOption) {
					price += parseFloat(deliveryOption.cost);
				}

				if (personalisedCard && messageCardType === "personalised" && info.free_card === false) {
					price += parseFloat(Config.personalisedCardPrice);
				}

				set({
					price: price.toFixed(2),
					originalPrice: originalPrice.toFixed(2),
					msrpPrice: msrpPrice.toFixed(2)
				});
			},
			SetSize: (size) => {
				set({ selectedSize: size });
				get().SetPrice();
				get().UpdateProductDetails();
			},
			SetId: (passedId) => {
				set({ id: passedId });

				get().SetPrice();
			},
			SetProductId: (productId) => set({ productId }),
			SetAddressSuggestions: (postcode) => {
				AddressAutocomplete(postcode).then((ret) => {
					if (ret.addresses !== undefined && ret.addresses.length > 0) {
						set({
							addressSuggestions: ret,
							showAddressSuggestions: true
						});
					} else {
						set({
							addressSuggestions: [],
							showAddressSuggestions: false
						});
					}
				});
			},
			SetAddress: (addressInformation) => {
				set({
					address: { ...get().address, ...addressInformation },
					showAddressSuggestions: false
				});
				get().UpdateProductDetails();
			},
			async AddToCart({ showButton = true }) {
				let productInfo = get().BuildProductInfo();
				return useCartStore.getState().AddToCart(productInfo, showButton);
			},
			GetAddPersonalisedCardLink() {
				const product_id = get().id;
				return `${Config.apiUrl}/${Config.apiVersion}/products/${product_id}/add-personalised-card`;
			},

			ConvertAddonIds: () => {
				const addons = get().selectedAddons;

				if (addons.length === 0) return [];

				return addons.map((addon) => {
					return get().availableAddons[addon].id;
				});
			},
			BuildProductInfo: () => {
				const selectedDeliveryDate = get().selectedDeliveryDate ?? new Date();
				let productInfo = {
					productid: get().id,
					attr_size: get().selectedSize ?? "",
					attr_addons: get().ConvertAddonIds(),
					attr_deliverydate: selectedDeliveryDate.getFullYear() + "-" + (selectedDeliveryDate.getMonth() + 1) + "-" + selectedDeliveryDate.getDate(),
					attr_deliverymethod: get().selectedDeliveryOption ? get().selectedDeliveryOption.name : "",
					attr_messagecard: get().messageCardText ?? "",
					attr_quantity: 1,
					...(get().printzwareId && { attr_personalised: get().printzwareId }),
					...(get().videoMsg && { attr_videomsg: get().videoMsg }),
					...get().address
				};

				Object.keys(productInfo).forEach((value) => {
					//check if the values are empty
					if (productInfo[value] === "") {
						delete productInfo[value];
					}
				});

				return productInfo;
			},
			ResetProductStore: () => {
				if (useAppStore.getState().isDebug) {
					console.log(`ProductStore.js - Resetting product store`);
				}

				set(itemSpecific);
			},
			SetAddedToCart: (id) => {
				if (useAppStore.getState().isDebug) {
					console.log(`ProductStore.js - Added to cart, setting cart id to ${id}`);
				}

				set({ cartId: id });
			}
		})),
		{
			name: "product-storage"
		}
	)
);

export { useProductStore };
