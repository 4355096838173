import React from "react";

import { Link } from "react-router-dom";

import "../assets/scss/components/_callout-banner.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { dates } from "../Config";

export const CalloutBanner = () => {
	const { todaysDate, firstDate, earlyChristmasDate } = dates;
	let calloutBannerLink = "/next-day-flowers";
	let calloutBannerText = "Seasonal bouquets with <strong>FREE DELIVERY</strong> from £19.99.";

	if (todaysDate > firstDate && todaysDate < earlyChristmasDate) {
		calloutBannerLink = "/christmas-flowers";
		calloutBannerText = "Christmas Flowers with FREE delivery from £19.99.";
	}

	return (
		<section className={`callout-banner ${todaysDate > firstDate && todaysDate < earlyChristmasDate ? "christmas-callout" : ""}`}>
			<Link to={calloutBannerLink}>
				{calloutBannerText}

				<span>
					{" "}
					Explore the range now! <FontAwesomeIcon icon={faArrowRight} />{" "}
				</span>
			</Link>
		</section>
	);
};
